import React, { useState } from "react";

import logo_company from "../assets/logo.png";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    // <header>
    //   <nav>
    //     <div>
    //       <img src={logo_company} alt="SGOVET IMPORT AND REPRESENTATION" />
    //     </div>
    //     <div>
    //       <a href="/">Inicio</a>
    //       <a href="/about">Productos</a>
    //       <a href="/contact">Servicios</a>
    //       <a href="/contact">Acerca de SGOVET</a>
    //     </div>
    //   </nav>
    // </header>

    <header>
      <nav class="bg-white border-gray-200 dark:bg-gray-800">
        <div class="flex flex-wrap justify-between items-center bg-secondary py-[10px] px-[1em] md:px-[3em]">
          <a href="/" class="flex items-center">
            <img
              src={logo_company}
              class="mr-3 h-[40px] sm:h-[60px]"
              alt="SGOVET IMPORT AND REPRESENTATION"
            />
          </a>
          <div class="flex items-center ">
            <button
              onClick={toggleMenu}
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="text-contrast inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded={isOpen}
            >
              <span class="sr-only">Open main menu</span>
              <svg
                class="w-[30px] h-[30px]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <svg
                class="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            id="mobile-menu-2"
            className={`${
              isOpen ? "block" : "hidden"
            } lg:flex flex-col lg:flex-row items-center w-full lg:w-auto transition-all duration-300 ease-in-out transform lg:transform-none ${
              isOpen ? "opacity-100 translate-y-0" : "opacity-0 md:opacity-100 -translate-y-5"
            }`}
          >
            <ul class="flex flex-col mt-4 font-medium items-center lg:flex-row lg:space-x-8 lg:mt-0">
              <li className="mb-[1em] lg:mb-[0]">
                <a
                  href="/"
                  class="block py-2 pr-4 pl-3 text-contrast"
                  aria-current="page"
                >
                  Inicio
                </a>
              </li>
              <li className="mb-[1em] lg:mb-[0]">
                <a href="productos" class="block py-2 pr-4 pl-3 text-contrast">
                  Productos
                </a>
              </li>
              <li className="mb-[1em] lg:mb-[0]">
                <a href="galeria" class="block py-2 pr-4 pl-3 text-contrast">
                  Galeria
                </a>
              </li>
              <li className="mb-[1em] lg:mb-[0]">
                <a href="/nosotros" class="block py-2 pr-4 pl-3 text-contrast">
                  Acerca de SGOVET
                </a>
              </li>
              <li className="mb-[1em] lg:mb-[0]">
                <a
                  href="/#contactos"
                  class="block py-[10px] px-[30px] text-contrast bg-primary rounded-[30px]"
                >
                  Contactos
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
