import React, { useState, useEffect } from "react";
import axios from "axios";

const IndexPage = () => {
  const [data, setData] = useState([]);

  // Obtener datos desde la API cuando el componente se monta
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://takihyo-textile-admin.herokuapp.com/api/taki-uflpa"
        ); // Reemplaza con la URL de tu API de Strapi
        const result = await response.json();
        setData(result); // Guardamos los datos en el estado
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, []); // Se ejecuta solo una vez cuando el componente se monta
  console.log(data.table2, "datadata");
  const [fileUploads, setFileUploads] = useState({});
  const [loading, setLoading] = useState(false); // Estado para el loader

  // Función para manejar el cambio de archivo y asociarlo con la fila y columna correspondiente
  const handleFileChange = (file, rowIndex, header) => {
    const cellKey = `fila${rowIndex + 1}columna${header}`; // Genera la clave única por celda
    setFileUploads((prev) => ({
      ...prev,
      [cellKey]: {
        file,
        row: rowIndex + 1,
        column: header,
        cellValue:
          data.table1.body[0][header] +
          " " +
          data?.table1?.body[rowIndex - 1].UI, // Captura el valor de la celda
      },
    }));
  };

  const [useText, setUseText] = useState("");
  const sendDataToAPI = async () => {
    if (Object.keys(fileUploads).length === 0) {
      return setUseText("There is no data to send");
    }

    setLoading(true); // Mostrar el loader antes de hacer la solicitud

    const payload = Object.entries(fileUploads).map(([key, value]) => ({
      Output: new Date().toLocaleDateString(), // Fecha actual
      desc: `${value.cellValue}`, // Descripción (valor de la celda y la columna)
      files: value.file, // El archivo PDF correspondiente
    }));

    const formData = new FormData();

    // Agregamos los archivos y los datos al FormData
    payload.forEach((item, index) => {
      formData.append(`files[${index}]`, item.files); // Archivo PDF
      formData.append(`data[${index}][Output]`, item.Output); // Fecha
      formData.append(`data[${index}][desc]`, item.desc); // Descripción
    });

    try {
      const response = await axios.post(
        "https://takihyo-textile-admin.herokuapp.com/api/taki-uflpa",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Success:", response.data);      
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      setFileUploads({});
      setLoading(false); // Ocultar el loader después de completar la solicitud
    }
  };

  // // Definir el orden de las columnas
  // const columnsToShow = [
  //   "UI",
  //   "1",
  //   "2",
  //   "3",
  //   "4",
  //   "5",
  //   "6",
  //   "7",
  //   "8",
  //   "9",
  //   "10",
  // ];

  // // Dividir los datos en dos tablas (primeros 5 y el resto)
  // const firstHalf = data.slice(0, 5);
  // const secondHalf = data.slice(6);

  // // console.log(firstHalf, "firstHalffirstHalf");

  // const filteredColumnsToShow = columnsToShow.filter(
  //   (column) => column !== "UI"
  // );

  // // Estado para almacenar los archivos PDF cargados
  // const [uploadedFiles, setUploadedFiles] = useState({});
  // const [submissionData, setSubmissionData] = useState([]);

  // const handleFileUpload = (e, rowIndex, columnIndex) => {
  //   const file = e.target.files[0];
  //   console.log(e.target, 'e.targete.target')
  //   if (file) {
  //     // Guardamos el archivo en el estado utilizando una estructura para identificar por fila y columna
  //     setUploadedFiles((prevFiles) => ({
  //       ...prevFiles,
  //       [`fila${rowIndex}Celda${columnIndex}`]: file, // Usamos una clave única para cada celda
  //     }));

  //     console.log(`Archivo cargado para fila ${rowIndex}, columna ${columnIndex}:`, file);
  //   }
  // };

  // const prepareDataForApi = () => {
  //   const dataToSend = [];

  //   // Verifica si uploadedFiles contiene datos
  //   console.log("Estado de uploadedFiles:", uploadedFiles);

  //   secondHalf.forEach((row, rowIndex) => {
  //     // Accedemos al archivo cargado para la fila (puedes ajustarlo según el formato que uses)
  //     const pdfFile = uploadedFiles[rowIndex]; // Aquí obtenemos el archivo basado solo en rowIndex

  //     if (pdfFile) {
  //       const output = new Date().toLocaleDateString("en-GB"); // Usamos la fecha actual
  //       const desc = `test`; // Combinamos la descripción y UI

  //       // Crear el objeto para enviar a la API
  //       dataToSend.push({
  //         Output: output,
  //         desc: desc,
  //         URL: pdfFile, // Aquí pasamos el archivo PDF
  //       });
  //     }
  //   });

  //   // Mostrar el array de datos antes de enviarlo
  //   console.log("Datos listos para la API:", dataToSend);

  //   return dataToSend; // Devuelve el array listo para enviar
  // };

  // const sendDataToApi = async () => {
  //   const apiUrl = "http://localhost:5432/api/taki-uflpa";
  //   const dataToSend = prepareDataForApi();
  //   console.log(dataToSend, 'dataToSend')

  //   if (dataToSend.length === 0) {
  //     alert("No hay archivos para enviar.");
  //     return;
  //   }

  //   try {
  //     const formData = new FormData();
  //     dataToSend.forEach((item, index) => {
  //       formData.append(`files[${index}][Output]`, item.Output); // Fecha
  //       formData.append(`files[${index}][desc]`, item.desc); // Descripción
  //       formData.append(`files[${index}][URL]`, item.URL); // Archivo PDF
  //       console.log(item.URL, 'item.URLitem.URLitem.URL')
  //     });
  //     const response = await axios.post(apiUrl, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     console.log("Respuesta de la API:", response.data);
  //     alert("Archivos enviados exitosamente.");
  //   } catch (error) {
  //     console.error("Error al enviar los datos:", error);
  //     alert("Hubo un error al enviar los archivos.");
  //   }
  // };
  console.log(data?.table2?.body[0]);
  return (
    <div className="App mb-[2em]">
      <br />
      {loading && (
        <div className="loader">
          <div className="file-upload">
            <h3>
              <b>
                Sending files <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>{" "}
              </b>
            </h3>
            <p>Please wait a moment</p>
          </div>
        </div>
      )}{" "}
      <ul className="max-w-[1500px] mb-[1em] m-auto">
        <li>
          {" "}
          <b>URL:</b> {data?.table2?.body[0].Setting}
        </li>
        <li>
          {" "}
          <b>Article Number:</b> {data?.table2?.body[1].Setting}
        </li>
      </ul>
      <table className="table-auto max-w-[1500px] m-auto border-none border border-gray-300 table-attachment">
        <tbody>
          {data?.table1?.body?.map((row, rowIndex) => (
            <tr key={rowIndex} className="border-t">
              {data.table1.headers?.map((header) => (
                <td
                  className="p-[0px] pl-[5px] w-[150px] text-center text-[12px]"
                  key={header}
                >
                  {row[header] === "YES" ? (
                    <>
                      <label
                        htmlFor={`fileInput-${rowIndex}-${header}`}
                        className="border border-[#FF0000] w-[23px] h-[23px] p-[5px] my-[10px] mx-auto block"
                      >
                        <svg
                          height="12px"
                          width="12px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 280.067 280.067"
                          fill="#949494"
                          stroke="#949494"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            <path
                              style={{ fill: "#949494" }}
                              d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429 c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0 s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394 c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599 c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403 c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485 c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098 l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"
                            />
                          </g>
                        </svg>
                      </label>
                      <input
                        type="file"
                        accept="application/pdf"
                        style={{ display: "none" }}
                        id={`fileInput-${rowIndex}-${header}`}
                        onChange={(e) =>
                          handleFileChange(e.target.files[0], rowIndex, header)
                        }
                        className="mt-2"
                      />
                    </>
                  ) : (
                    row[header]
                  )}
                </td>
              ))}
            </tr>
          )) || (
            <tr>
              <td
                colSpan={data?.table1?.headers?.length || 1}
                className="text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* <table className="table-auto max-w-[1500px] m-auto border-none border border-gray-300 table-attachment">
        <thead className="bg-gray-200">
          {firstHalf.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnsToShow.map((column, colIndex) => (
                <th
                  key={colIndex}
                  className="text-left font-normal text-[10px] px-[10px]"
                >
                  {row[column] || ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {secondHalf.map((row, rowIndex) => (
            <tr key={rowIndex} className="border-t">
              {rowIndex % 2 === 0 ? (
                <td
                  rowSpan={2}
                  className="border-gray-300 p-[0px] pl-[5px] w-[150px]"
                >
                  <div>{secondHalf[rowIndex].description}</div>
                  <div className="mt-2">
                    <p className="text-left text-[12px]">
                      {secondHalf[rowIndex].UI || "N/A"}
                      {secondHalf[rowIndex + 1]?.UI || "N/A"}
                    </p>
                  </div>
                </td>
              ) : null}

              {filteredColumnsToShow.map((column, colIndex) => (
                <td
                  key={colIndex}
                  className={`border-gray-300 pb-[7px] ${
                    column === "YES" && uploadedFiles[`${rowIndex}-${column}`]
                      ? "bg-primary" // Cambiar el color de fondo si el archivo ha sido cargado
                      : ""
                  }`}
                >
                  {row[column] === "YES" ? (
                    <>
                      <input
                        type="file"
                        accept=".pdf"
                        id={`file-${rowIndex}-${column}`}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileUpload(e, rowIndex, column)}
                      />
                      <button
                        className="border border-[#FF0000] p-[5px] m-auto block"
                        onClick={() =>
                          document
                            .getElementById(`file-${rowIndex}-${column}`)
                            .click()
                        }
                      >
                        
                         <svg
                          height="15px"
                          width="15px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 280.067 280.067"
                          fill="#949494"
                          stroke="#949494"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                          <g id="SVGRepo_iconCarrier">
                            <path
                              style={{ fill: "#949494" }}
                              d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429 c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0 s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394 c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599 c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403 c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485 c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098 l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"
                            />
                          </g>
                        </svg> 
                      </button>
                    </>
                  ) : (
                    <p className="text-center text-[16px] font-bold">
                      {row[column] || ""}
                    </p>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table> */}
      <div className="flex flex-col justify-center items-center mt-[1em]">
        <p className="my-[1em]">Attach your file then</p>

        {/* <p style={{ color: "tomato", marginBottom: "20px" }}>{useText}</p> */}
        <button
          className="bg-primary px-[3em] py-[10px] rounded-[10px] font-bold text-contrast"
          onClick={() => sendDataToAPI()}
          disabled={Object.keys(fileUploads).length === 0 ? true : false}
        >
          SAVE
        </button>
      </div>
    </div>
  );
};

export default IndexPage;
