import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../components/layout";

import IndexPage from "../pages/index";

export const WrapRoot = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<IndexPage />} />
          {/* <Route path="nosotros" element={<NosotrosPage />} /> */}
       
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
